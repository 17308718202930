exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-360-eat-guide-js": () => import("./../../../src/pages/360-eat-guide.js" /* webpackChunkName: "component---src-pages-360-eat-guide-js" */),
  "component---src-pages-360-the-collection-js": () => import("./../../../src/pages/360-the-collection.js" /* webpackChunkName: "component---src-pages-360-the-collection-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arets-bonde-js": () => import("./../../../src/pages/arets-bonde.js" /* webpackChunkName: "component---src-pages-arets-bonde-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maisha-js": () => import("./../../../src/pages/maisha.js" /* webpackChunkName: "component---src-pages-maisha-js" */),
  "component---src-pages-malmo-gastronomy-award-js": () => import("./../../../src/pages/malmo-gastronomy-award.js" /* webpackChunkName: "component---src-pages-malmo-gastronomy-award-js" */),
  "component---src-pages-senchaism-js": () => import("./../../../src/pages/senchaism.js" /* webpackChunkName: "component---src-pages-senchaism-js" */),
  "component---src-pages-smart-js": () => import("./../../../src/pages/smart.js" /* webpackChunkName: "component---src-pages-smart-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-svenska-gastronomipriset-js": () => import("./../../../src/pages/svenska-gastronomipriset.js" /* webpackChunkName: "component---src-pages-svenska-gastronomipriset-js" */),
  "component---src-pages-white-guide-green-js": () => import("./../../../src/pages/white-guide-green.js" /* webpackChunkName: "component---src-pages-white-guide-green-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-workshop-bench-js": () => import("./../../../src/pages/workshop-bench.js" /* webpackChunkName: "component---src-pages-workshop-bench-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */),
  "component---src-pages-workshop-stool-js": () => import("./../../../src/pages/workshop-stool.js" /* webpackChunkName: "component---src-pages-workshop-stool-js" */)
}

